/* tailwind.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom.css */

body {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Montserrat Subrayada', sans-serif;
  font-family: 'Noto Nastaliq Urdu', serif;
  font-family: 'Noto Sans Kawi', sans-serif;
}


.carousel img:not(:first-child) {
  margin-left: -100vw;
  animation: slide-in 1s infinite;
  -webkit-animation: slide-in 1s infinite;
}

@keyframes slide-in {
  0% {
    margin-left: -100vw;
  }

  100% {
    margin-left: 0;
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 10px;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #F37521;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-track:hover {
  background-color: #fff;
}