@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #F4F5F9 0% 0% no-repeat padding-box;
  font-family: 'Montserrat', sans-serif;
}

