/* Style for the container */
.otp-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  /* Style for individual OTP input boxes */
  .otp-container input[type="text"] {
    width: 40px;
    height: 40px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 50%; /* To make it rounded */
    margin: 5px;
    outline: none; /* To remove the default focus outline */
  }
  
  /* Optional: Add some additional styling to highlight the active input */
  .otp-container input[type="text"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  #recaptcha-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  .cat-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }  
  .cat-container::-webkit-scrollbar {
    display: none;
  }