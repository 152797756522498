/* Navbar start */

body {
  background: #f4f5f9 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: 'Montserrat', sans-serif;
}

#logo {
  height: 60px;
  margin-left: -10px;
  margin-top: 10px;
}

#created {
  width: 120px;
  padding: 10px;
  margin-top: 10px;
  border: 2px solid black;
  background-color: #F4F5F9;
  color: #f37521;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 10px 10px #00000012;
  border: 2px solid #f37521;
  border-radius: 35px;
  opacity: 1;
  
}

#login {
  width: 100px;
  padding: 12px;
  margin-top: 10px;

  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  border: 2px solid black;
  background-color: white;
  color: white;
  /* padding: 14px 28px; */
  font-size: 16px;
  cursor: pointer;
  background: #f37521 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #00000012;
  border: 0.15449999272823334px solid #f37521;
  border-radius: 35px;
  opacity: 1;
  font-weight: bold;
}

#Homeinput {
  width: 370px;
  border-radius: 30px;
  margin-bottom: 30px;
  height: 50px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

#NavbarImage1 {
  width: 70px;
  margin-left: -10px;
  margin-top: 5px;
}

#NavbarImage2 {
  width: 80px;
  margin-top: 5px;
  margin-left: -20px;
}

#NavbarImage3 {
  width: 90px;
  margin-left: -20px;
  margin-top: 5px;
}

/* Navbar End */

#realstate {
  text-decoration: none;
  color: #000000;
}

.myhome {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.Home {
  margin-top: 9px;
}

.Home img {
  width: 100%;
  border-radius: 45%;
  margin-top: 20px;
  height: 150px;
}

.Home h5 {
  text-align: center;
  align-items: center;
  font-size: 20px;
  padding-top: 14px;
  font-weight: bold;
}

.Home p {
  font-size: 12px;
  text-align: center;
  align-items: center;
  color: #000000;
  padding-top: 8px;
  opacity: 0.8;
}

/* section2 */

.section2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  margin-bottom: -20px;
}

.section2 img {
  width: 100%;
  height: 300px;
  margin-top: 20px;
 border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-origin: border-box;
  /* position: sticky; */
}




/* section2 */

.section3 {
  display: flex;
  max-width: 1400px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section3 img {
  width: 630px;
  height: 234px;
  margin-top: 20px;
}

/* section 4 */
.section4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.cardtitle {
  display: flex;
  justify-content: space-between;
  color: #f37521;
  margin-inline: 1rem;
  padding-top: 10px;
}

.cardtext {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.4;
  font-size: 12px;
  margin-inline: 1rem;
  margin-top: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-inline: 2rem;
  padding-bottom: 22px;
}

#sec5image {
  width: 100%;
}

/* 6 */
.section {
  justify-content: space-evenly;
  padding: 47px;
}

.section img {
  width: 40%;
  margin-inline: 5rem;
}

/* .section6 {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 60px;
}

#sec6image {
  width: 100%;
  height: 110px;

  opacity: 1;
} */

/* section 7 */

.sections7 {
  display: flex;
  justify-content: space-evenly;
  background: grey;
  height: 309px;
}

.sections7 h3 {
  margin-top: 120px;
  color: #f37521;
}

.sections7 p {
  font-size: 14px;
}

.section7 {
  display: flex;
}

.sections8 {
  display: flex;
  justify-content: space-between;
}

.section7 img {
  width: 167px;
  height: 121px;
  margin-top: 30px;
}

/* Modal section start */

.modal {
  display: flex;
  justify-content: center;
}

.modal Modal {
  text-align: center;
  align-items: center;
}

.modal p {
  text-align: center;
}

.login #inputlogin {
  text-align: start;
  padding: 18px;
  align-items: center;
  margin-inline: 3rem;
  width: 80%;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #0000000f;
  border-radius: 55px;
  opacity: 1;
}

.HomeLogin {
  display: flex;
  justify-content: center;
}

.HomeLogin button {
  margin-top: 40px;
  padding: 15px;
  width: 58%;
  color: white;
  text-align: center;
  align-items: center;
  margin-left: 40px;
  background: #f37521 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #0000000f;
  border: 0.15449999272823334px solid #f37521;
  border-radius: 55px;
  opacity: 1;
}

.SignWN {
  display: flex;
  justify-content: center;
}

.SignWN button {
  margin-top: 20px;
  background: #f37521 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #0000000f;
  border: 0.15449999272823334px solid #f37521;
  border-radius: 37px;
  opacity: 1;
  width: 40%;
  padding: 10px;
  color: white;
}

/*  */
.MobileNumber {
  display: flex;
  justify-content: center;
}

.MobileNumber button {
  text-align: center;
  align-items: center;
  width: 70%;
  padding: 14px;
  color: white;
  margin-top: 30px;
  background: #f37521 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #0000000f;
  border: 0.15449999272823334px solid #f37521;
  border-radius: 55px;
  opacity: 1;
}

.EmailAddress {
  display: flex;
  justify-content: center;
}

.EmailAddress button {
  text-align: center;
  align-content: center;
  align-items: center;
  width: 70%;
  padding: 14px;
  color: white;
  margin-top: 40px;
  background: #f37521 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #0000000f;
  border: 0.15449999272823334px solid #f37521;
  border-radius: 55px;
  opacity: 1;
}


.form-check {
    margin-inline: 3rem;
    padding-top: 20px;
}


