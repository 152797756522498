body{
  background: #F4F5F9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.VehicleSearch{

background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 9px;
opacity: 1;
padding: 20px;
display: flex;
justify-content: space-around;
}
.categories-container::-webkit-scrollbar {
    display: none
}


.searchv{
  display: flex;
  justify-content: space-center;
 width: 130px;
background: #F37521 0% 0% no-repeat padding-box;
border: 0.15449999272823334px solid #F37521;
border-radius: 9px;
opacity: 1;
  text-align: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
}




.searchv img{
  width: 20px;
  margin-left: 20px;
  
}



.background-image {
  background-image: url("../../images/Group\ 3201.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-origin: border-box;
 padding: 30px;
  /* max-width: 1400px; */
  height: 100%;
  /* justify-content: center;
align-items: center; */
}

@media (max-width: 640px) {
  .max-h-screen-sm {
    max-height: 150px; /* Adjust this value as needed for smaller screens */
  }
}


