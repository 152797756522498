body {
  background: #f4f5f9 0% 0% no-repeat padding-box;
  opacity: 1;
}

.realestate-background-image {
  background-image: url("../../images/bimagerealstate.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-origin: border-box;
  padding: 30px;
  /* max-width: 1400px; */
  height: 100%;
  /* justify-content: center;
align-items: center; */
}
.categories-container::-webkit-scrollbar {
  display: none
}
.card {
  /* border-bottom-right-radius: 20px; */
  /* border-bottom-left-radius: 20px; */
  border-radius: 20px;
}

.res {
  background-color: white;
  width: 80%;
  margin: 40px auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
}

.realstatesearch {
  display: flex;
  justify-content: space-between;
  margin-inline: 1rem;
  padding-bottom: 10px;
}

.realstatesearch p {
  padding: 15px;
  margin-top: 10px;
}

.realstatesearch img {
  padding-left: 13px;
}

.RSbtn {
  display: flex;
  margin-top: 40px;
  padding: 10px;
  text-align: center;
  align-items: center;
  width: 120px;
  background: #f37521 0% 0% no-repeat padding-box;
  border: 0.15449999272823334px solid #f37521;
  border-radius: 9px;
  opacity: 1;
}

.RSbtn img {
  width: 40px;
  padding-right: 12px;
}


@media (max-width: 640px) {
  .max-h-screen-sm {
    max-height: 150px;
    /* Adjust this value as needed for smaller screens */
  }
}